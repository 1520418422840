<template>
  <VContent>
    <template slot="right">
      <span>الأخبار</span>
    </template>
    <template slot="left">
      <b-button variant="info" size="md" class="text-light" v-b-modal.post-modal
        >اضافة</b-button
      >
    </template>
    <template slot="content">
      <b-table
        :items="items"
        :fields="fields"
        :tbody-transition-props="transProps"
        tbody-tr-class="text-muted"
        responsive
        table-class="table-font h-100"
      >
        <template v-slot:cell(image)="{ item }">
          <img :src="ImageURL(item)" width="150" height="80" />
        </template>
        <template v-slot:cell(content)="{ item }">
          <b-button variant="success" pill @click="getByID(item.id, true)"
            >عرض النص</b-button
          >
        </template>
        <template v-slot:cell(created_at)="{ item }">
          <div class="p-2 rounded-pill green">
            {{ new Date(item.created_at).toISOString().slice(0, 10) }}
          </div>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown size="lg" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" animation="cylon"></b-icon>
            </template>
            <b-dropdown-item @click="getByID(item.id, true)">
              <b-icon
                icon="file-earmark-medical"
                scale="0.5"
                class="ml-2"
              ></b-icon>
              عرض</b-dropdown-item
            >
            <b-dropdown-item @click="getByID(item.id, false)">
              <b-icon icon="pencil-square" scale="0.5" class="ml-2"></b-icon>
              تعديل</b-dropdown-item
            >
            <b-dropdown-item @click="remove(item.id)">
              <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
              حذف</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </template>
    <template slot="modals">
      <PostModal
        :readonly="readonly"
        @close="readonly = false"
        state="NEWS"
      ></PostModal>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import PostModal from "./components/postModal";
import {
  successAlert,
  errorAlert,
  showAlertWithConfirm,
  ImageURL,
} from "@/utils/helper";

export default {
  components: { PostModal },
  data() {
    return {
      page: 1,
      readonly: false,
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "image",
          label: "صورة الخبر",
          sortable: true,
        },
        {
          key: "title",
          label: "عنوان الخبر",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ النشر",
          sortable: true,
        },
        {
          key: "content",
          label: "النص",
          sortable: true,
        },
        {
          key: "actions",
          label: "العمليات",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.admin.news.list;
    },
    meta() {
      return this.$store.state.admin.news.meta;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("admin/news/get", this.page);
    },
    getByID(id, status) {
      this.$store
        .dispatch("admin/news/show", id)
        .then(() => {
          this.readonly = status;
          this.$bvModal.show("post-modal");
        })
        .catch(() => errorAlert());
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("admin/news/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("admin/news/DELETE_NEWS", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
    ImageURL(item) {
      return item.image ? item.image : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss"></style>
